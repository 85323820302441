import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid, makeStyles, TextField } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { setLoading } from "../../../ducks/loading";
import { useRouteMatch, withRouter } from "react-router-dom";
import { getSeasonKpiDetails, getSeasonKpiTeamTrends, getSeasonKpiTrends, getSingleSeasonDetails } from "../../../ducks/game";
import Trends from "./Trends";
import qs from 'query-string';
import { formatDate, getSearchValue, trensdCycle } from "../../../helpers";
import { Autocomplete } from "@material-ui/lab";
import classNames from "classnames";


const useStyles = makeStyles((theme) => ({
    root: {
        // width: '100%',
        margin: '15px 40px 3px 40px'
    },
    cycles: {
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        margin: '10px 10px 20px',
    },
    cycle: {
        border: '1px solid #d7d7d7',
        borderRadius: 25,
        padding: '5px 10px',
        marginRight: 15,
        fontSize: 13,
        fontWeight: 500,
        cursor: 'pointer',
    },
    selectedCycle: {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        color: 'white',
        border: '1px solid',
        borderRadius: 25,
        padding: '5px 10px',
        marginRight: 15,
        fontSize: 13,
        fontWeight: 500,
        cursor: 'pointer',
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: '3px 7px !important',
    },
    selectionType: {
        margin: 0,
        width: 140,
        position: 'absolute',
        right: '15px',
        top: '10%',
        '& .MuiOutlinedInput-root': {
            padding: '3px !important'
        },
        '& .MuiFormControl-root': {
            '& label': {
                top: '0px',
                fontSize: 12
            }
        }
    },
    trends: {
        boxShadow: '0px 0px 4px 0px rgba(161, 161, 161, 0.25)',
        padding: 12,
        border: '1px solid #f4f4f4cc',
        background: 'white'
    }
}));

const attributeType = [
    { name: 'Actual', id: 'actual' }

];

const KpiPerformance = ({ history, match, from, filtersData, getSeasonKpiTrends, getSeasonKpiTeamTrends, getSeasonKpiDetails, seasonDetails }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const qryparams = qs.parse(history.location.search);
    const [kpiTrends, setKpiTrends] = useState();
    const [attribute, setAttribute] = useState(attributeType[0]);
    const [selectedCycle, setSelectedCycle] = useState(trensdCycle[1]);
    const [selectedKpi, setSelectedKpi] = useState();

    let kpiType = getSearchValue(history, 'kpiType')

    useEffect(() => {
        match.params.kpiID && getKpiDetails(qryparams ? qryparams?.season : filtersData?.season?._id, match.params.kpiID);
    }, [match.params.kpiID])
    useEffect(() => {
        if (from === 'TEAM') getKpiTeamTrneds(qryparams ? qryparams?.season : filtersData?.season?._id, match.params.kpiID)
        else getKpiTrneds(qryparams ? qryparams?.season : filtersData?.season?._id, match.params.kpiID)
    }, [selectedCycle, attribute]);


    // ---------------team trends details---------
    const getKpiTeamTrneds = (seasonId, kpiId) => {
        setLoading(true);
        getSeasonKpiTeamTrends({
            seasonId: seasonId,
            kpiId: kpiId,
            params: { mtd_based: seasonDetails?.mtd_based, type: kpiType, attribute: attribute?.id, duration: selectedCycle?.value, cycle: selectedCycle?.id, limit: 5000, page: 1 },
            onSuccessCB: (result) => {
                let response = {
                    datasets: result?.datasets,
                    labels: result?.labels.map((lb) => (formatDate(lb)))
                }
                setKpiTrends(response);
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Kpi Trndes', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }
    // ---------------player trends details---------
    const getKpiTrneds = (seasonId, kpiId) => {
        setLoading(true);
        getSeasonKpiTrends({
            seasonId: seasonId,
            kpiId: kpiId,
            params: { mtd_based: seasonDetails?.mtd_based, type: kpiType, attribute: attribute?.id, duration: selectedCycle?.value, cycle: selectedCycle?.id, limit: 5000, page: 1 },
            onSuccessCB: (result) => {
                let response = {
                    datasets: result?.datasets,
                    labels: result?.labels.map((lb) => (formatDate(lb)))
                }
                setKpiTrends(response);
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Kpi Trndes', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    // ------------kpi details-----------
    const getKpiDetails = (seasonId, kpiId) => {
        getSeasonKpiDetails({
            seasonId: seasonId,
            kpiId: kpiId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result) => {
                setSelectedKpi(result);
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Kpi Trndes', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    const handleTrend = (cycle) => {
        setSelectedCycle(cycle);
    }

    return (
        <div className={classes.root}>
            <div>
                <p>{selectedKpi?.name}</p>
            </div>
            <div className={classes.trends}>
                <Grid container spacing={2}>
                    <Grid item lg={7} md={12} xs={12} style={{ position: 'relative' }}>
                        <div className={classes.cycles}>
                            {
                                trensdCycle.map((tr) => {
                                    return (
                                        <div
                                            className={selectedCycle.name === tr.name ? classes.selectedCycle : classes.cycle}
                                            onClick={() => handleTrend(tr)}>
                                            {tr.shortCode}
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/* <div className={classes.selectionType}>
                            <Autocomplete
                                clearable
                                disabled
                                id='attribute'
                                autoComplete
                                options={attributeType}
                                size="small"
                                getOptionLabel={(option) => option.name}
                                value={attribute}
                                classes={classes.autocompleteRoot}
                                renderInput={(params) => <TextField {...params} label="Attribute" size='small' variant="outlined" />}
                                onChange={(e, val) => setAttribute(val)}
                            />
                        </div> */}
                        <div>
                            <Trends kpiTrends={kpiTrends} />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div >
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.user,
    seasonDetails: state.game.seasonDetails
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getSingleSeasonDetails: (options) => dispatch(getSingleSeasonDetails(options)),
        getSeasonKpiTrends: (options) => dispatch(getSeasonKpiTrends(options)),
        getSeasonKpiTeamTrends: (options) => dispatch(getSeasonKpiTeamTrends(options)),
        getSeasonKpiDetails: (options) => dispatch(getSeasonKpiDetails(options)),

    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KpiPerformance));