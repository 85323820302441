import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { setLoading } from "../../../../ducks/loading";
import { Chart } from "chart.js";

let chart;

const useStyles = makeStyles((theme) => ({
    root: {
    }
}));



const LineCharts = ({ kpiTrends }) => {
    const classes = useStyles();

    const config = {
        type: 'line',
        data: kpiTrends,
        options: {
            scales: {
                x: {
                    ticks: {
                        display: false
                    },
                    grid: { display: false }
                },
                y: {
                    grid: { display: false }
                }
            },
            plugins: {
                legend: {
                    display: false,
                }
            }
        }
    };

    const ctx = document.getElementById('lineChart');

    if (chart) chart.destroy();
    chart = new Chart(ctx, config);


    return (
        <div className={classes.root}>
            <canvas id="lineChart"></canvas>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LineCharts));